import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "../components/Navigation/NavBar";
import CustomInput from "../components/CustomInput";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL, HOME_PATH, PROFILE_PATH } from "../utility/path";
import { getConfig, getConstant, handleErrorResponse, handleHeader, validateServiceCharge } from "../services/functions";
import { spinner } from "../redux/slices/spinnerSlice";
import axios from "axios";
import CustomSelect from "../components/CustomSelect";
import { setHeader } from "../redux/slices/headerSlice";
import MainContainer from "../components/MainContainer";
import { showAlert } from "../redux/slices/alertSlice";

const ChangeService = ({ }) => {

  const dispatch = useDispatch();
  const profile = useSelector(state => state.profile);
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state;

  const [state, setState] = useState({
    category: data?.category,
    fee: data?.fee,
    id: data?.id,
    aboutme:data?.aboutme
  });

  const handleChange = input => e => {
    console.log('innn',input)
    if(input === 'fee'){
      const numericValue = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
      const truncatedValue = numericValue.slice(0, 5); // Limit to 6 characters
      setState({ ...state, [input]: truncatedValue });
    }else{
      setState({ ...state, [input]: e.target.value });
    }
    
  }

  const handleClick = async (event) => {
    event.preventDefault();
    //dispatch(updateProfileAddressAction(state));
    dispatch(spinner(true));
    const config = getConfig();

    try {
      let formData = new FormData();
      formData.append("id", state.id);
      formData.append("fee", state.fee);
      formData.append("category", state.category);
      formData.append("aboutme", state?.aboutme);

      const { data } = await axios.post(`${BASE_URL}/api/users/updateProfileCategory`, formData, config);
      dispatch(setHeader({ gotoName: "Home", gotoAction: HOME_PATH }));
      dispatch(spinner(false));
      dispatch(showAlert({ message: 'Success', success: true }));
      navigate(PROFILE_PATH);
      return data;
    } catch (error) {
      console.log(error?.message);
      dispatch(spinner(false));
      const errorResponse = handleErrorResponse(error, dispatch);
      return errorResponse;
    }
  }

  const renderForm = () => {
    return (
      <>
        {
          data.category.length ?
            (<CustomSelect title="Category" handleChange={(e) => handleChange(e)} name="category" defaultOptionValue="Select service category" data={data.categoriesData} state={state} isDisable={false} />)
            : (<CustomSelect title="Category" handleChange={(e) => handleChange(e)} name="category" defaultOptionValue="Select service category" data={data.categoriesData} state={state} isDisable={false} />)
        }

        {/* <CustomInput title="Approx. service fee*" id="feeId" type="number" placeholder="&emsp;&emsp;&emsp;&emsp;Service charge*" handleChange={handleChange} name="fee" value={state?.fee} preInputText="INR" maxLength={5}/> */}
        <CustomInput title="About me" id="aboutme" type="text" placeholder="Enter about work" handleChange={handleChange} name="aboutme" value={state?.aboutme} maxLength={100}/>

        <button type="button" className="btn btn-primary" onClick={(e) => handleClick(e)} disabled={profile?.loading || !validateServiceCharge(state?.fee) || !state?.category}>{profile?.loading ? "Loading.." : "Save"}</button>
      </>
    );
  }

  useEffect(() => {
    handleHeader({...getConstant("CHANGE_SERVICE")},dispatch);

    return () => {}
  }, []);

  return (
    <>
      <NavBar />
      <MainContainer>
        {renderForm()}
      </MainContainer>
    </>
  );
}

export default ChangeService;
