import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { detailsAction } from "../redux/slices/detailsSlice";
import Alert from '../components/Alert';
import { addPostAction, postsAction } from '../redux/slices/postsSlice';
import { addRatingAction } from '../redux/slices/ratingsSlice';
import NavBar from '../components/Navigation/NavBar';
import CustomInput from '../components/CustomInput';
import CommentModel from '../components/CommentModel';
import Rating from '../components/Rating';
import StarRating from '../components/StarRating';
import { followAction, unfollowAction } from '../redux/slices/usrSlice';
import CustomIcon from '../components/CustomIcon';
import CustomImage from '../components/CustomImage';
import { handleErrorResponse, validatePincode_v2 } from '../services/functions';

const Details = () => {
  const { id } = useParams();

  const authData = useSelector(state => state?.auth?.data);
  const detailsData = useSelector(state => state?.details?.data);
  const details = useSelector(state => state?.details);
  const postsData = useSelector(state => state?.posts?.data);


  const dispatch = useDispatch();
  const [counter, setCounter] = useState(0);
  const [isShowInit, setIsShowInit] = useState(false);
  const [state, setState] = useState({ id: authData?.id, otherUserId: id, comment: "", rating: '', postOffice: '' });

  const commentsSlice = detailsData?.commentByOthers?.slice(0, 2);
  const commentByOthersData = detailsData?.commentByOthers;
  const commentsCount = detailsData?.commentByOthers?.length;

  const ratingByOthersData = detailsData?.ratingByOthers || [];
  const ratingByOthersGroupByData = groupBy(ratingByOthersData, rating => rating.rating);
  const alreadyRated = ratingByOthersData.filter((el) => {
    return el.user[0] === authData?.id
  }).length;

  const alreadyFollowing = detailsData?.followers?.find(u => u?.toString() === authData?.id?.toString());
  const hideFollow = detailsData?.followers?.find(u => u?.toString() == authData?.id?.toString());
  const hideMobile = detailsData?.category?.find(u => u?.name?.toLowerCase() === "user");
  
  useEffect(() => {
    let ignore = false;

    const fetchData = async () => {
      if (!ignore) {
        await dispatch(detailsAction({ ...state }));
        await validatePincode_v2(detailsData?.pincode, setState);
      }
    };

    fetchData();

    return () => { ignore = true; }
  }, [id]);

  const handleSubmit = (type, rating) => e => {
    e.preventDefault();
    //setIsShowInit(true);
    console.log("submit", state,authData);

    if(!authData || !authData?.isLoggedIn){
      handleErrorResponse({ message: "Please login!!" }, dispatch);
      return;
    }

    if (type === "post") {
      dispatch(addPostAction({ ...state }));
      setState({ ...state, comment: "" })
    }

    if (type === "rating") {
      dispatch(addRatingAction({ ...state, rating: rating }));
      setState({ ...state, rating: "" })
    }

    if (type === "follow") {
      dispatch(followAction({ ...state }));
    }

    if (type === "unfollow") {
      dispatch(unfollowAction({ ...state }));
    }



    // setTimeout(() => {
    //   console.log('timeout')
    //   setIsShowInit(false);
    // }, 5000);

  }

  const handleClose = (e) => {
    e.preventDefault();
    setIsShowInit(false);
  };

  const handleChange = input => e => {
    e.preventDefault();
    setState({ ...state, [input]: e.target.value });
  }

  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  const handlePhoneCall = () => {
    // Replace `1234567890` with the actual phone number
    window.location.href = "tel:"+detailsData.mobile;
  };

  const handleWhatsApp = () => {
    // Replace `1234567890` with the actual phone number
    window.location.href = "https://api.whatsapp.com/send?phone="+detailsData.mobile;
  };



  return (
    <>
      <NavBar />

      <div className="main-container">
        <div className="d-flex flex-column align-items-center">
          {!details.loading && !details.appErr && !details.serverErr ? (<>
            <div className="mt-3 mb-3">
              {/* <CustomImage className="circle" src="https://picsum.photos/300/300"/> */}
              <CustomImage state={{image:detailsData?.profilePhoto,name:detailsData.name}} className={"detail_page__image"} size={"18rem"}/>
            </div>

            {(authData?.id !== detailsData?.id && alreadyFollowing  && !hideMobile) ? 
            (<p>
              <button className="btn btn-outline-warning mb-3" onClick={handleSubmit("unfollow")}>Unfollow</button>
            </p>) : ''}

            {(authData?.id !== detailsData?.id  && !alreadyFollowing && !hideMobile) ? 
            (<p>
              <button className="btn btn-outline-info mb-3" onClick={handleSubmit("follow")}>Follow</button></p>
            ) : ''}

            <div className="d-flex flex-column">
              <p className="capitalize"><CustomIcon type="user" />&nbsp;{detailsData.name}</p>
              {!hideMobile ? (<p className="capitalize">
                <CustomIcon type="address" />
                &nbsp;{detailsData.address},
                &nbsp;{detailsData.pincode},
                &nbsp;{state?.postOffice?.Name},
                &nbsp;{state?.postOffice?.Division},
                &nbsp;{state?.postOffice?.Region},
                &nbsp;{state?.postOffice?.State},
                &nbsp;{state?.postOffice?.Country}</p>) : ''}

              {!hideMobile ? (<p><CustomIcon type="phone" />&nbsp;<a href={`tel:${detailsData.mobile}`} onClick={handlePhoneCall}>{detailsData.mobile}</a></p>) : ''}
              {/* <p><CustomIcon type="whatsapp" />&nbsp;<a href={`https://api.whatsapp.com/send?phone=${detailsData.mobile}`} onClick={handleWhatsApp}>{detailsData.mobile}</a></p> */}


              {!hideMobile ? (<p className="capitalize"><CustomIcon type="app" />&nbsp;{detailsData?.category?.[0].name}</p>) : ''}
              {/* {!hideMobile ? (<p><CustomIcon type="currency" />&nbsp;{detailsData.fee} <i><small>(service charge* )</small></i></p>) : ''} */}
              {!hideMobile && detailsData?.aboutme ? <p className="capitalize-first"><CustomIcon type="info" />&nbsp;{detailsData?.aboutme}</p> : ''}
              
            </div>

            {!hideMobile ? (<Rating ratingByOthersGroupByData={ratingByOthersGroupByData} ratingByOthersData={ratingByOthersData} />) : ''}
            <button type="button" className="btn btn-outline-primary mb-3" data-toggle="modal" data-target="#exampleModal" disabled={commentByOthersData?.length < 1}>
              View reviews({commentByOthersData?.length})
            </button>

            {commentByOthersData?.length > 0 && <CommentModel commentByOthersData={commentByOthersData} />}
            {!hideMobile  && alreadyRated < 1 && authData?.id !== detailsData?.id ? (<StarRating handleSubmit={handleSubmit} />) : ''}

            {/* <CustomInput title="" id="ratingId" type="number" placeholder="Enter rating" handleChange={handleChange} name="rating" value={state.rating}/> */}
            {/* <button className="btn btn-outline-primary mb-3" onClick={handleSubmit("rating")}>Post ratings</button> */}

            {authData?.id !== detailsData?.id && !hideMobile ? (<>
              <CustomInput title="Post comments" id="postId" type="text" placeholder="type comment here..." handleChange={handleChange} name="comment" value={state.comment} />
              <button className="btn btn-outline-primary mb-3" onClick={handleSubmit("post")} disabled={!state.comment}>Post comments</button>
            </>) : ''}
          </>) : ''}

          {!details.loading && (details.appErr || details.serverErr) ? (<div>Seems some issue, please try again later!!</div>) : ""}
        </div>
      </div>

    </>
  );
}

export default Details;
