import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import CustomIcon from "../components/CustomIcon";
import CustomImage from "../components/CustomImage";
import CustomInput from "../components/CustomInput";
import CustomSelect from "../components/CustomSelect";
import NavBar from "../components/Navigation/NavBar";
import SubscribeNotification from "../components/SubscribeNotification";
import { listCategoryAction } from "../redux/slices/categoriesSlice";
import { getProfileAction } from "../redux/slices/profilesSlice";
import {CHANGE_ADDRESS_PATH, CHANGE_IMAGE_PATH, CHANGE_PASSWORD_PATH, CHANGE_SERVICE_PATH } from "../utility/path";
import H from "../components/H";
import { getConstant, handleErrorResponse, handleHeader } from "../services/functions";

const Profile = ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authData = useSelector(state => state?.auth?.data);
  const profile = useSelector(state => state?.profile);
  const categoriesData = useSelector(state => state?.categories?.data);
  const [isCategoryUser, setIsCategoryUser] = useState(false);
  const [toggle,setToggle] = useState(profile?.data?.category?.length ? true : false);
  
  

  const [showTransition,setShowTransition] = useState(false);
  useEffect(() => {
    handleHeader({...getConstant("PROFILE")},dispatch);
    setShowTransition(true);
    dispatch(getProfileAction(authData))
    dispatch(listCategoryAction(authData));
    setToggle(()=>{return profile?.data?.category?.length ? true : false});
    
    return ()=>{}

  }, []);

  useEffect(() => {
    if (categoriesData) {
      const isUserCategory = categoriesData.some(c => c.name === 'user' && profile?.data?.category?.length > 0 && profile?.data?.category[0] === c.id);
      setIsCategoryUser(isUserCategory);
    }
  }, [categoriesData, profile?.data?.category]);

  // handle field change
  const handleChange = input => e => {
    e.preventDefault();
  }

  const getBase64 = file => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };

  const handleClickAddress = (event) =>{
    event.preventDefault();
    //dispatch(updateProfileAddressAction(state));
    navigate(CHANGE_ADDRESS_PATH,{state:{address:profile?.data?.address,pincode:profile?.data?.pincode,id:profile?.data?.id}});
    
  }

  const handleClickChangePassword = (event) =>{
    event.preventDefault();
    //dispatch(updateProfileAddressAction(state));
    navigate(CHANGE_PASSWORD_PATH,{state:{id:profile?.data?.id}});
  }

  const handleClickChangeImage = (event) =>{
    event.preventDefault();
    //dispatch(updateProfileAddressAction(state));
    navigate(CHANGE_IMAGE_PATH,{state:{image:profile?.data?.profilePhoto,id:profile?.data?.id}});
  }

  const handleClickChangeService = (event) =>{
    event.preventDefault();
    if(isCategoryUser){
      handleErrorResponse({message:'Operation not allowed[001]!!'},dispatch)
    }
    //dispatch(updateProfileAddressAction(state));
    navigate(CHANGE_SERVICE_PATH,{state:{id:profile?.data?.id,category:profile?.data?.category,fee:profile?.data?.fee,categoriesData:categoriesData,aboutme:profile?.data?.aboutme}});
  }
  
  const renderForm = () =>{
    return (
      <>
        <div className="bg-light border border-light rounded p-1 mb-2">
        <h5>Photo</h5>
          <div className="mb-3">
            <CustomImage state = {{image:profile?.data?.profilePhoto,name:profile?.data?.name}} className="profile__image" size={"5rem"}/>
          </div>
          
          <button type="button" className="btn btn-outline-primary mb-3" onClick={(e)=>handleClickChangeImage(e)}>
                Edit Photo <CustomIcon type="pencil-square"/>
          </button>
        </div>
        
        <div className="bg-light border border-light rounded p-1 mb-2">
        <h5>Personal Info</h5>
          <CustomInput title="" id="nameId" type="text" placeholder="Your name" handleChange={handleChange} name="name" value={profile?.data?.name || ''} isDisable={true} addCssToInput="capitalize"/>
          <CustomInput title="" id="mobileId" type="text" placeholder="Your mobile" handleChange={handleChange} name="mobile" value={profile?.data?.mobile || ''} isDisable={true}/>
          <CustomInput title="" id="oldPasswordId" type="password" placeholder="Password" handleChange={handleChange} name="oldPassword" isDisable={true} value="******"/>
          <button type="button" className="btn btn-outline-primary mb-3" onClick={(e)=>handleClickChangePassword(e)}>
                Edit Password <CustomIcon type="pencil-square"/>
          </button>
        </div>

        <div className="bg-light border border-light rounded p-1 mb-2">
        <h5>Address</h5>
          <CustomInput title="" id="addressId" type="text" placeholder="Your address" handleChange={handleChange} name="address" value={profile?.data?.address || ''} isDisable={true} addCssToInput="capitalize"/>
          <CustomInput title="" id="pincodeId" type="number" placeholder="Your pincode" handleChange={handleChange} name="pincode" value={profile?.data?.pincode || ''} isDisable={true}/>
          <button type="button" className="btn btn-outline-primary mb-3" onClick={(e)=>{handleClickAddress(e)}}>
            Edit Address <CustomIcon type="pencil-square"/>
          </button>
        </div>

        {!isCategoryUser ? (<div className="bg-light border border-light rounded p-1 mb-2">
          {!profile?.data?.category?.length ? (<div className="form-check form-switch mb-3">
            <input className="form-check-input"
              type="checkbox" role="switch" id="flexSwitchCheckChecked"
              checked={toggle}
              onChange={()=> setToggle(!toggle)} />
            <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Join as a <b>{!toggle ? 'Professional?' : 'User?'}</b></label>
          </div>) : ''}
          {toggle || profile?.data?.category?.length
        ? (<>
          <CustomSelect title="Category " handleChange={handleChange} name="category" defaultOptionValue="Select category" data={categoriesData} state={{category:profile?.data?.category||''}} isDisable={true} tooltipInfo={"Note: Category cannot be changed once created!!"}/>
          
          {/* <CustomInput title="Approx service fee*" id="feeId" type="number" placeholder="&emsp;&emsp;&emsp;&emsp;Service charge*" handleChange={handleChange} name="fee" value={profile?.data?.fee} isDisable={true} preInputText="INR"/> */}
          
          {profile?.data?.aboutme ? <div className="form-floating mb-3"><H value="About me"></H><p className="disabled-bg-color p-2 rounded capitalize-first">{profile.data.aboutme}</p></div> : ''}

          <button type="button" className="btn btn-outline-primary mb-3" onClick={(e)=>handleClickChangeService(e)}>
            Edit Service <CustomIcon type="pencil-square"/>
          </button>
        </>) 
        : ''}
        </div>) : ''}

        <div className="bg-light border border-light rounded p-1 mb-2">
          <SubscribeNotification notificationEnabled={profile?.data.notificationEnabled}/>
        </div>
      </>
    );
  }

  return (
    <>
    <NavBar/>
    <div className={`main-container show-transition ${showTransition ? "show" : ""}`}>
      <div className="row">
        <div className="col-md-6 offset-md-3">
          {!profile?.loading && !profile?.appErr && !profile?.serverErr ? (renderForm()) : ''}
          {!profile?.loading && (profile?.appErr || profile?.serverErr) ? (<div>Error, please try again later!!</div>) : ''}
        </div>
      </div>
    </div>
    </>
  );
}

export default Profile;
