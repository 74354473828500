import CustomInput from "../components/CustomInput";
import CustomSelect from "../components/CustomSelect";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getConstant, handleHeader, validatePincode, validateServiceCharge } from "../services/functions";
import H from "../components/H";
import { setNameAction } from "../redux/slices/authSlice";

const EnterName = ({ nextStep, previousStep, handleChange, state }) => {
  const categoriesData = useSelector(state => state?.categories?.data);
  const [toggle,setToggle] = useState(true);
  // const [isCategoryUser, setIsCategoryUser] = useState(
  //   categoriesData.some(c => c.name === 'user' && (profile?.data?.category?.length > 0 && profile?.data?.category[0] === c.id))
  // );


  const Continue = e => {
    e.preventDefault();
    //nextStep();
    dispatch(setNameAction({...state,locationPathname:location?.pathname}));
  }

  const [showTransition,setShowTransition] = useState(false);
  const dispatch = useDispatch();

  useEffect(()=>{
    setShowTransition(true);
    handleHeader({...getConstant("ENTER_DETAILS")},dispatch);
    return ()=>{setShowTransition(false)}
  },[]);

  const _handleChange = (e) =>{
    return handleChange(e);
}


  return (
    <div className={`show-transition ${showTransition ? "show" : ""}`}>
      <H value={"Enter Details"}/>
      <CustomInput title="Name*" id="nameId" type="text" placeholder="Enter name" handleChange={handleChange} name="name" value={state?.name}/>
      <CustomInput title="Address*" id="addressId" type="text" placeholder="Enter address" handleChange={handleChange} name="address" value={state?.address}/>
      <CustomInput title="Pincode*" id="pincodeId" type="number" placeholder="Enter pincode" handleChange={handleChange} name="pincode" value={state?.pincode} maxLength={6} state={state}/>
      {state?.postOffice && <p className="text-primary"><small>{state?.postOffice?.Name},&nbsp;</small><small>{state?.postOffice?.Division},&nbsp;</small><small>{state?.postOffice?.Region},&nbsp;</small><small>{state?.postOffice?.State},&nbsp;</small><small>{state?.postOffice?.Country}.</small></p>}

      {/* <div className="form-check form-switch mb-3">
        {input className="form-check-input"
          type="checkbox" role="switch" id="flexSwitchCheckChecked"
          onChange={()=> setToggle(!toggle)} />}
        <label className="form-check-label" htmlFor="flexSwitchCheckChecked"><b>Select Professional Category</b></label> }
      </div> */}

      {/* {toggle ? (
        <>
          <CustomSelect title="Category " handleChange={(e)=>handleChange(e)} name="category" defaultOptionValue="Select service category" data={categoriesData} state={state} isDisable={false}/>
          <CustomInput title="Service charge*" id="feeId" type="number" placeholder="Enter service charge*" handleChange={handleChange} name="fee" value={state?.fee} maxLength={10}/>
        </>
      ) : ''} */}
      
      <CustomSelect title="Select Category " handleChange={_handleChange} name="category" defaultOptionValue="Select category" data={categoriesData} state={state} isDisable={false}/>
      {/* {!state?.isUserCategory ? (<CustomInput title="Service charge*" id="feeId" type="number" placeholder="Enter service charge*" handleChange={handleChange} name="fee" value={state?.fee} maxLength={10}/>) : ''} */}

      <button className="btn btn-primary" onClick={previousStep} >Back</button>
      <button className="btn btn-primary m-2" onClick={Continue} 
      disabled={(( state?.errorpincode || !state?.name || !state?.address || !validatePincode(state?.pincode)) ||
        (toggle && ( !state?.isUserCategory && !validateServiceCharge(state?.fee) || !state?.category)))} >Submit</button>
        <div>{state?.error}</div>
    </div>
  );
}

export default EnterName;
